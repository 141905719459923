export default function Page(){
    return <div className="page">
        <div className="container">
            {/* Start About */}
            <div className="about" id="about">
                <div className="main-title">About us</div>
                <div className="container">
                    <div className="text">
                        <div className="title">Who we are ?</div>
                        <p>RZ was born of a passion for hospitality and love to create beautiful 
                            spaces. Our journey began with a vision 
                            to provide a unique and welcoming sanctuary for people 
                            seeking a break from the hustle and bustle of everyday life.</p>
                    </div>
                    <div className="image">
                        <img src={require("../media/about.png")} alt=""/>
                        <span></span>
                    </div>
                </div>
            </div>
            {/* About */}

            {/* Start Features */}
            <div className="features" id="features">
                <div className="main-title">features</div>
                <div className="container">
                    <div className="image">
                        <img className="o-1" src={require("../media/f1.png")} alt=""/>
                        <img className="o-2" src={require("../media/f2.png")} alt=""/>
                        <img className="o-3" src={require("../media/f3.png")} alt=""/>
                    </div>
                    <div className="feat">
                        <div className="point">
                            <span></span>
                            <ul>
                                <li>Private pool</li>
                                <li>Fully equipping/heating</li>
                                <li>Parking space</li>
                                <li>Outdoor BBQ area </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Features */}

            {/* Start Map */}
            <div className="contact" id="contact">
            <div className="main-title">contact us</div>
                <div className="container">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d54198.33339104519!2d35.73823749057566!3d31.895840727680852!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151ca50b837dbc39%3A0x23ff69d64b47729c!2sMarj%20Al%20Hamam%2C%20Amman!5e0!3m2!1sen!2sjo!4v1724321665325!5m2!1sen!2sjo"
                        width="600"
                        height="450"
                        style={{ border: 0 }}
                        allowFullScreen
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        title="Google Map"
                    ></iframe>
                    <div className="con">
                        <div className="title">Get in touch</div>
                        <div className="c">
                            <span className="ti">Adress</span>
                            <span>Adress</span>
                        </div>
                        <div className="c">
                            <span className="ti">Contact</span>
                            <span>0785443210</span>
                            <span>contact@gmail.com</span>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Map */}
        </div>
    </div>
}