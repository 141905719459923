export default function Footer(){
    return <div className="footer">
        <div className="container">
            <div className="logo">
                <img src={require("../media/logo.png")} alt=""/>
            </div>
            <div  className="left">
                <div className="l-one">
                    <div className="title">Lorem</div>
                    <ul>
                        <li>
                            <a href="#about">Home</a>
                        </li>
                        <li>
                            <a href="#home">About us</a>
                        </li>
                        <li>
                            <a href="#features">Features</a>
                        </li>
                        <li>
                            <a href="#contact">Contact us</a>
                        </li>
                    </ul>
                </div>
                <div className="l-one">
                <div className="title">Lorem</div>
                    <ul>
                        <li>
                            <a href="#home">Home</a>
                        </li>
                        <li>
                            <a href="#about">About us</a>
                        </li>
                        <li>
                            <a href="#features">Features</a>
                        </li>
                        <li>
                            <a href="#contact">Contact us</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="bottom">
            <span>2024 RZ All right reserved</span>
        </div>
    </div>
}