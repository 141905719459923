export default function Header(){
    return <div className="header">
        <div className="container">
            <div className="logo">
                <img src={require("../media/logo.png")} alt="" />
            </div>
            <div className="nav">
                <ul>
                    <li>
                        <a href="#home">Home</a>
                    </li>
                    <li>
                        <a href="#about">About us</a>
                    </li>
                    <li>
                        <a href="#features">Features</a>
                    </li>
                    <li>
                        <a href="#contact">Contact us</a>
                    </li>
                </ul>
            </div>
            <div className="search">
                <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_3744_248)">
                        <path d="M55.146 51.887L41.588 37.786C45.074 33.642 46.984 28.428 46.984 23C46.984 10.318 36.666 0 23.984 0C11.302 0 0.984009 10.318 0.984009 23C0.984009 35.682 11.302 46 23.984 46C28.745 46 33.282 44.564 37.161 41.838L50.822 56.046C51.393 56.639 52.161 56.966 52.984 56.966C53.763 56.966 54.502 56.669 55.063 56.129C56.255 54.982 56.293 53.08 55.146 51.887ZM23.984 6C33.358 6 40.984 13.626 40.984 23C40.984 32.374 33.358 40 23.984 40C14.61 40 6.98401 32.374 6.98401 23C6.98401 13.626 14.61 6 23.984 6Z" fill="black"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_3744_248">
                        <rect width="56.966" height="56.966" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            </div>
            <button class="menu">
                    <span></span>
                    <span></span>
                    <span></span>
            </button>
        </div>
    </div>
}