export default function Landing(){
    return <div className="landing" id="home">
        <div className="container">
            <div className="text">
                <div className="title">Relax in Our  Cozy Home
                with a Private Pool</div>
                <p>Your perfect getaway starts here</p>
                <a className="btn" href="#home">Book</a>
            </div>
        </div>
        <img className="lan-image" src={require("../media/landing.png")} alt="logo"/>
    </div>
}